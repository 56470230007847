/* ORIGINAL SCSS imports. */
/* missing: navigation, home, interior */
// @import "branding";
// @import "navigation";
// @import "home";
// @import "interior";
// @import "footer";
// @import "animate";
@import "abstracts/variables";

/* CSS -> SCSS converted at https://css2sass.herokuapp.com */
/* In case of corruption, see: https://beautifytools.com/css-to-scss-converter.php */
body {
	background-color: $gray-white3;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "proxima-nova", sans-serif;
	font-weight: 500;
}

h1 {
	font-size: 32px;
}

h2 {
	font-size: 30px;
	color: $brandcolor2;
	margin-top: 0px;

	small {
		font-weight: 300;
	}
}

h3 {
	font-weight: 300;
	font-size: 24px;
	color: $brandcolor1;

	small {
		font-size: 80%;
		font-weight: 300;
	}
}

h4 {
	font-size: 18px;
	color: $brandcolor1-light;
}

h5 {
	font-size: 16px;
	color: $gray-dark1;
	font-weight: 300;

	strong {
		font-weight: 700;
	}
}

p {
	color: $gray-dark1;
}

a {
	color: $brandcolor3;
	text-decoration: none;

	&:focus, &:active, &:hover {
		color: $brandcolor3-dark;
		text-decoration: underline;
	}
}

ol, ul {
	padding-left: 20px;
	color: $gray-dark1;
}

.well {
	background: $gray-white1;
	color: $brandcolor1;

	h3 {
		margin-top: 0px;
	}
}

.alert-warning {
	background: $brandcolor3-light;
	border-color: $brandcolor3;
	color: $brandcolor3-dark;
}

.alert-danger a {
	color: $alertcolor1;
	text-decoration: underline;

	&:hover, &:focus, &:active {
		color: $alertcolor1;
	}
}

.form-control {
	border-radius: 0 !important;
}

.page-content {
	p + form {
		margin-top: 40px;
	}

	form {
		margin-top: 25px;

		.add-bkg {
			background: $gray-white1;
		}

		.panel {
			margin-bottom: 40px;

			h3 {
				position: relative;
				margin-bottom: 15px;
				display: block;
				color: $brandcolor1;
				margin-top: 5px;
			}
		}
	}
}

.error-group {
	input.error, select.error, textarea.error {
		background: $alertcolor1-light !important;
		margin-bottom: 0;
		border-color: $alertcolor1-dark;
	}

	small {
		display: block;
		padding: 4px 5px;
		background: $alertcolor1-dark;
		color: $white;
	}

	label.error {
		display: block;
		padding: 4px 5px;
		background: $alertcolor1-dark;
		color: $white;
		color: $gray-dark1;
	}
}

.btn-primary {
	background: $brandcolor3;
	color: $white;
	border-color: $brandcolor3-light;
	text-decoration: none;
	transition: all 0.3s ease;

	&:active, &:focus, &:hover {
		background-color: $brandcolor3-dark;
		text-decoration: none;
		border-color: $brandcolor3-light;
	}
}

.btn-default {
	text-decoration: none;
}

.btn-link {
	color: $brandcolor3;
	text-decoration: none;

	&:active, &:focus, &:hover {
		color: $brandcolor3-dark;
		text-decoration: underline;
	}
}

.btn-outline-medium {
	border: 1px solid $brandcolor1-extralight;
}

label {
	font-family: "proxima-nova", sans-serif;
	font-weight: 500;
	color: $brandcolor1;
	font-size: 16px;
}

.label-default {
	background: $brandcolor1;
	color: $white;
	font-weight: 500;

	&.active {
		background: $brandcolor2;
	}
}

.system-message, .system-error-message {
	padding: 20px;
	background-color: $gray-white1;
	border: 1px solid $gray-light1;
	color: $gray-dark1;
	margin: 0;
	min-height: auto;
	height: auto !important;
	height: auto;
}

.system-message h1, .system-error-message h1 {
	color: $brandcolor1;
	margin: 0 0 20px 0;
}

.system-message {
	a:link, .system-error-message a:link {
		color: $brandcolor1-dark;
	}
}

.system-error-message {
	a:link, .system-error-message a:link {
		color: $brandcolor1-dark;
	}
}

#apply-modal .modal-body .system-message {
	display: none;
}

blockquote {
	border-color: $brandcolor1;
}

@media (min-width: 768px) {
	.checkbox-inline.checkbox-margin {
		margin-top: 22px;
	}
}

.alert-phone {
	margin: 0;
	padding: 0 10px 0 30px;
	border-radius: 0;
	text-align: center;
	background: $brandcolor1;
	color: $white;
	font-weight: 500;
	font-family: "proxima-nova", sans-serif;
	font-size: 24px;
	line-height: 60px;

	a {
		color: $white;
		text-decoration: none;

		&:hover, &:active {
			text-decoration: none;
		}
	}

	.close {
		font-size: 40px;
		color: $brandcolor1-light;
		text-shadow: none;
		line-height: 60px;
		opacity: 1;
	}
}

.navbar {
	// .container {
	// 	max-width: none !important;
	// }
	margin-bottom: 0;
	background: $white;
	border-radius: 0;
	.navbar-toggler {
		background: $brandcolor1;
		border-color: $brandcolor1;
		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
		}
		&.collapsed {
			background: none;
			border-color: $gray-light1;
			.navbar-toggler-icon {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2873, 90, 136, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
			}
		}
	}
	.navbar-toggle {
		margin-top: 13px;
		background: $brandcolor1;
		border-color: $brandcolor1;

		&.collapsed {
			background: none;
			border-color: $gray-light1;

			span {
				background: $brandcolor1;
			}
		}

		span {
			background: $white;
		}
	}

	.navbar-brand {
		background-image: url(webart/logos/iowa-donor-network-logo.png);
		text-indent: -9999px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		height: 60px;
		width: 300px;
		margin: 10px 0;

		span {
			display: none;
		}
	}

	.navbar-collapse {
		#main-nav {
			a {
				text-decoration: none;
				font-weight: 500;
				font-family: "proxima-nova", sans-serif;
			}

			> ul {
				margin: 0 -15px;

				> li {
					border-bottom: 1px solid $brandcolor1-light;

					&.open a i {
						display: none;

						+ i {
							display: block;
							color: $brandcolor1-light;
						}
					}

					> a {
						color: $white;
						padding: 25px 15px;
						background: $brandcolor1;
						font-size: 18px;

						i {
							float: right;
							font-size: 22px;
							color: $white;

							+ i {
								display: none;
							}
						}
					}

					.dropdown-menu li {
						border-bottom: 1px solid $gray-light1;

						&:first-child {
							border-top: 1px solid $gray-light1;
						}

						&.active > a {
							background: $brandcolor2;
							color: $white;
						}

						a {
							padding: 20px 15px;

							&:hover, &:active {
								background: $brandcolor2;
								color: $white;
							}
						}
					}
				}
			}
		}

		.mobile-utility {
			margin: 0 -15px;

			.btn-group {
				&:nth-child(2) {
					width: 0.4%;
					border-left: 1px solid $brandcolor2-dark;

					.btn {
						background: $brandcolor2-dark;

						&.collapsed {
							background: $brandcolor2;
						}
					}
				}

				.btn {
					border-radius: 0;
					background: $brandcolor2;
					color: $white;
					font-size: 18px;
					font-weight: 500;
					font-family: "proxima-nova", sans-serif;
					padding: 15px 12px;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.navbar-utility {
		height: 70px;
		padding: 20px 0;
		background: $gray-white2;

		.container {
			padding: 0;

			i {
				font-size: 18px;

				&.fa-times {
					color: $gray-dark1;
				}
			}
		}

		ul {
			list-style: none;
			float: right;
			margin: 0;
			padding: 0;

			li {
				margin: 0;
				padding: 0;
				float: left;

				&:hover {
					cursor: pointer;
				}

				&.phone a {
					margin-right: 10px;
					color: $gray-dark1;
					text-decoration: none;
					pointer-events: none;
					font-weight: 300;
					font-family: "proxima-nova", sans-serif;
					font-size: 20px;
					line-height: 30px;
				}
			}

			&.navbar-utility-menu {
				&.logged-in li {
					&:nth-child(2) a {
						background: $brandcolor3;
						color: $white;
						border-color: $brandcolor3-light;

						&:hover {
							background: $brandcolor3-dark;
							border-color: $brandcolor3-light;
						}
					}

					&:last-child a {
						background: $brandcolor3;
						color: $white;

						&:hover {
							background: $brandcolor3-dark;
							border-color: $brandcolor3-dark;
						}
					}
				}

				li:last-child a {
					background: $brandcolor3;
					color: $white;
					border-color: $brandcolor3-light;

					&:hover {
						background: $brandcolor3-dark;
						border-color: $brandcolor3-light;
					}
				}

				a {
					display: block;
					padding: 0 10px;
					margin-left: 5px;
					border: 1px solid $gray-light1;
					height: 30px;
					line-height: 30px;
					color: $brandcolor1;
					font-weight: 500;
					font-family: "proxima-nova", sans-serif;
					transition: all 0.4s ease;
					text-decoration: none;
					background: $white;

					&:hover, &:active {
						background: $brandcolor1;
						color: $white;
						text-decoration: none;
					}
				}
			}
		}

		.btn-link {
			float: right;
			color: $brandcolor1;
			padding: 4px 12px;

			&:hover, &:focus, &:active {
				color: $brandcolor1-dark;
				text-decoration: none;
			}
		}

		.navbar-search {
			float: right;

			#search-toggle-btn {
				background: $white;
				border-color: $gray-light1;
				color: $brandcolor1;
				transition: all 0.4s ease;

				&:hover {
					background: $brandcolor1;
					color: $white;
					text-decoration: none;

					i.fa-times {
						color: $white;
					}
				}
			}

			.form-control {
				border-radius: 0;
				height: 30px;
				padding: 0;
				text-align: center;
			}

			.btn {
				border-radius: 0;
				background: $brandcolor3;
				height: 30px;
				line-height: 30px;
				width: 30px;
				padding: 0;
				text-align: center;
				color: $white;
				border: 1px solid $brandcolor3-light;
			}
		}
	}

	.navbar {
		border-bottom: 5px solid $brandcolor1-light;
		border-left: none;
		border-right: none;
		background: $gray-white2;

		.navbar-brand {
			background-repeat: no-repeat;
			background-position: center top;
			background-size: contain;
			background-position: center center;
			height: 60px;
			margin-top: -30px;
			width: 350px;
			font-size: 12px;
			color: $brandcolor2;
			text-align: center;
			font-weight: 300;
			text-decoration: none;
			font-family: "proxima-nova", sans-serif;
			font-style: italic;

			span {
				display: none;
			}
		}

		.navbar-collapse {
			margin: 0 -15px;

			#main-nav > ul {
				display: block;
				border-left: 1px solid $gray-light1;
				border-right: 1px solid $gray-light1;
				background: $white;
				float: right;
				margin: 0;

				> li {
					height: 60px;
					width: 102px;
					border: none;

					&.open > a, &:hover > a {
						background: $brandcolor1-light;
						color: $white;
					}

					&.active > a {
						background: $brandcolor1;
						color: $white;
						border-color: $brandcolor1-dark;
					}

					&:last-child ul {
						left: auto;
						right: 0;
					}

					> a {
						background: $white;
						color: $brandcolor1;
						font-size: 14px;
						height: 65px;
						padding: 0;
						text-align: center;
						width: 102px;
						display: table-cell;
						vertical-align: middle;
						transition: border 0.3s ease;
						border-bottom: 5px solid $brandcolor1;

						i {
							display: none !important;
						}
					}

					&.dropdown {
						padding: 0;

						> ul.dropdown-menu {
							padding-bottom: 0;
							padding-top: 0;
							border-top: 5px solid $brandcolor1-dark;

							li {
								&.active > a {
									background: $brandcolor2;
								}

								a {
									padding: 10px 15px;

									&:hover {
										background: $brandcolor1;
										color: $white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.navbar {

		.container {
			padding: 0 15px;
		}

		.navbar-collapse #main-nav > ul {
			margin: 0 -15px;

			> li {
				width: 134px;

				> a {
					width: 134px;
					font-size: 15px;
				}
			}
		}
	}
}

@media (min-width: 1200px) {
	.navbar {
		.navbar-brand {
			background-image: url(webart/logos/iowa-donor-network-logo.png);
			background-size: contain;
			background-position: center center;
			height: 120px;
			width: 220px;
			padding: 95px 0 0;
			margin: -70px 0 -5px;

			&:hover span {
				display: none;

				+ span {
					display: block;
				}
			}

			span {
				display: block;
				color: $brandcolor2;

				+ span {
					display: none;

					i {
						font-size: 20px;
					}
				}
			}
		}

		.navbar-collapse #main-nav > ul {
			display: inline;
			float: right !important;

			> li {
				width: 135px;

				> a {
					width: 135px;
					font-size: 16px;
				}
			}
		}
	}
}

.page-content > .container > .row > .col-md-3,
.page-content > .container > .row > .col-md-4 {
	padding: 0;
	margin-bottom: 20px;

	#side-nav-toggle {
		border: none;
		border-radius: 0px;
		background: $brandcolor2-dark;
		color: $white;
		padding: 15px 0;
		font-weight: 300;
		font-family: "proxima-nova", sans-serif;
		font-size: 20px;
		text-align: center;

		&.collapsed {
			background: $brandcolor2;

			i {
				display: none;

				+ i {
					display: inline;
				}
			}
		}

		i {
			display: inline;
			text-transform: uppercase;

			+ i {
				display: none;
			}
		}
	}

	.btn-primary {
		margin: 0 15px;
		display: block;
	}

	#side-nav-collapse {
		&.affix {
			position: relative !important;
		}

		#side-nav {
			a {
				text-decoration: none;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			> ul {
				// display: none;

				&.level {
					display: block;
				}

				> li {
					border-left: 5px solid $white;

					&:last-child a {
						border-bottom: none;
					}

					&:hover {
						border-left: 5px solid $brandcolor1-light;
					}

					&.active {
						border-left: 5px solid $brandcolor1-light;

						> {
							ul {
								display: block;
								background: $gray-white2;
								margin-left: -5px;

								> li {
									border-left: 5px solid $brandcolor1-light;

									&.active > {
										ul {
											display: block;
											background: $gray-light1;

											> li {
												&.active > a {
													background: $brandcolor1-dark;
													color: $white;
												}

												a {
													display: block;
													padding: 10px;
													color: $brandcolor1;
													border-left: 5px solid $brandcolor1;

													&:hover, &:active {
														text-decoration: none;
													}

													&:hover {
														border-color: $brandcolor1-dark;
													}
												}
											}
										}

										a {
											background: $brandcolor1;
											color: $white;
											border-color: $brandcolor1;

											i {
												display: none;

												+ i {
													display: block;
												}
											}
										}
									}

									> a {
										display: block;
										padding: 10px;
										color: $brandcolor1;

										i {
											float: right;
											font-size: 20px;

											+ i {
												display: none;
											}
										}

										&:hover, &:active {
											text-decoration: none;
										}

										&:hover {
											border-left: 5px solid $brandcolor1;
											margin-left: -5px;
										}
									}
								}
							}

							a {
								background: $brandcolor1-light;
								color: $white;
								border-bottom: none;
								margin-top: -1px;

								i {
									display: none;
									font-size: 20px;

									+ i {
										display: block;
									}
								}
							}
						}
					}

					> a {
						display: block;
						padding: 20px 15px;
						color: $gray-mid3;
						overflow: hidden;
						transition: background 0.2s ease;
						border-bottom: 1px solid $gray-light1;

						i {
							float: right;
							font-size: 20px;

							+ i {
								display: none;
							}
						}

						&:hover, &:active {
							text-decoration: none;
						}
					}

					ul {
						display: none;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.page-content > .container > .row > .col-md-3,
	.page-content > .container > .row > .col-md-4 {
		padding-left: 15px;
		padding-right: 15px;
		margin: 0;

		#side-nav-collapse {
			&.collapse {
				display: block;
				visibility: visible;
				height: auto !important;
			}

			#side-nav ul {
				box-shadow: 0 0 0 transparent;

				> li {
					&:last-child {
						border-bottom: none;
					}

					> a {
						padding: 10px 15px;
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.page-content > .container > .row > .col-md-3 #side-nav-collapse.affix,
	.page-content > .container > .row > .col-md-4 #side-nav-collapse.affix {
		position: fixed !important;
		top: 20px;
		width: 212px;
	}
}

@media (min-width: 1200px) {
	.page-content > .container > .row .col-md-3 #side-nav-collapse.affix,
	.page-content > .container > .row .col-md-4 #side-nav-collapse.affix {
		width: 262px;
	}
}

#features {
	background: $black;
	transition: background 0.4s ease;
	margin-top: 1px;
	box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.65);

	.item {
		height: 175px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		a {
			display: block;
			height: 100%;
			width: 100%;
		}
	}

	.carousel-indicators {
		display: none;
		bottom: 0;

		li {
			margin: 1px 2px;

			&.active {
				margin: 1px 2px;
				height: 10px;
				width: 10px;
			}
		}
	}
}

.home-content {
	border-top: 1px solid $white;
	background-color: $white;

	.welcome {
		text-align: center;
		background: none;
		padding: 40px 0;

		h2 {
			text-transform: uppercase;
			color: $gray-dark1;
			font-size: 40px;
			font-weight: 600;
			font-family: "proxima-nova", sans-serif;

			small {
				text-transform: capitalize;
				display: block;
				color: $gray-mid3;
				font-weight: 300;
			}

			span {
				color: $brandcolor2;
				display: block;
			}
		}

		p {
			font-size: 16px;
		}
	}

	.col-md-4 {
		padding: 0;

		#calendar-toggle {
			margin: -15px 0 0px;
			border: none;
			border-radius: 0px;
			background: $brandcolor2;
			color: $white;
			padding: 15px 0;
			font-weight: 300;
			font-family: "proxima-nova", sans-serif;
			font-size: 20px;
			text-align: center;

			&.collapsed span {
				display: none;

				+ span {
					display: inline;
				}
			}

			span {
				display: inline;
				text-transform: uppercase;

				+ span {
					display: none;
				}
			}
		}

		#calendar {
			background: $gray-white1;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
			font-weight: 500;
			font-family: "proxima-nova", sans-serif;
			text-align: center;

			.calendar-title {
				background: $brandcolor2;
				color: $white;
				padding: 20px 0;
				text-transform: uppercase;
				font-size: 20px;
				border-bottom: 1px solid $white;
			}

			.calendar-list {
				text-align: center;

				.calendar-item {
					display: none;
					padding: 10px 0;
					transition: all 0.4s fadein;
					text-decoration: none;

					&:nth-child(even) {
						background: $gray-white1;
						border-top: 1px solid $white;
					}

					&:nth-child(odd) {
						border-bottom: 1px solid $gray-white3;
					}

					&:nth-child(1) {
						display: block;
					}

					&:nth-child(2) {
						display: block;
						animation-delay: 0.2s;
						-webkit-animation-delay: 0.2s;
					}

					&:nth-child(3) {
						display: block;
						animation-delay: 0.4s;
						-webkit-animation-delay: 0.4s;
					}

					&:nth-child(4) {
						display: block;
						animation-delay: 0.6s;
						-webkit-animation-delay: 0.6s;
					}

					&:nth-child(5) {
						display: block;
						animation-delay: 0.8s;
						-webkit-animation-delay: 0.8s;
					}

					&:nth-child(6) {
						display: none;
					}

					&:hover {
						text-decoration: none;
						background: $brandcolor1;
						box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.25);

						.calendar-item-title {
							color: $white;
						}

						.calendar-item-location, .calendar-item-date {
							color: rgba(255, 255, 255, 0.65);
						}
					}

					span {
						display: block;
					}

					.calendar-item-title {
						color: $brandcolor1;
						font-size: 16px;
					}

					.calendar-item-location {
						color: $gray-dark1;
						font-weight: 300;
					}

					.calendar-item-date {
						color: $gray-mid3;
						font-weight: 300;

						span {
							display: inline;
						}
					}
				}
			}

			.calendar-footer {
				display: block;
				padding: 12px 0;
				background: $brandcolor2;
				color: $white;
				text-decoration: none;
				transition: all 0.3s ease;
				border-bottom: 1px solid $white;

				&:hover, &:active, &:focus {
					background: $brandcolor2-dark;
					text-decoration: none;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	#features {
		.carousel-indicators {
			display: block;
		}

		.item {
			height: 350px;
		}
	}

	.home-content {
		background-image: url(webart/bkg/tree.svg);
		background-repeat: no-repeat;
		background-position: left bottom;

		.welcome {
			text-align: left;
			border-bottom: 1px solid $gray-light1;

			h2 span {
				display: inline;
			}
		}

		.stats {
			padding: 10px 0 20px;

			h3 {
				text-align: center;
				text-transform: uppercase;
				font-size: 22px;
				font-family: "proxima-nova", sans-serif;
				font-weight: 300;
				color: $brandcolor1-light;
				margin-bottom: 0;
			}
		}

		.callout-main-stat .col-sm-7 {
			color: $brandcolor1;
			font-size: 120px;
			line-height: 120px;
			text-align: right;
			font-weight: 500;
			font-family: "proxima-nova", sans-serif;

			.callout-main-container {
				display: table;
				width: 100%;

				.callout-main-item {
					display: table-cell;
					vertical-align: middle;
					height: 126px;
				}
			}
		}

		.col-md-4 {
			float: right;
			padding: 0 15px;

			#calendar {
				&.collapse {
					display: block;
					visibility: visible;
					height: auto !important;
				}

				.calendar-title {
					background: $brandcolor1;
					color: $white;
					height: 60px;
					line-height: 60px;
					padding: 0;
					font-size: 20px;
					font-weight: 300;
					border-bottom: 1px solid $white;
				}

				.calendar-list .calendar-item {
					padding: 17px 0;

					&:nth-child(5) {
						display: none;
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	#features .item {
		height: 450px;
	}

	.home-content {
		.callout-main-stat .col-sm-7 .callout-main-container .callout-main-item {
			height: 172px;
		}

		.stats {
			padding: 12px 0 36px;

			.col-md-3 {
				float: left;

				+ .col-md-3 {
					display: none;
				}
			}

			.callout-stat {
				height: 132px;
				margin: 20px 0;
				border: 5px solid $brandcolor1-light;
				border-radius: 132px;
				transition: all 0.4s ease;
				text-align: center;
				font-weight: 500;
				font-family: "proxima-nova", sans-serif;
				font-size: 13px;
				color: $gray-dark1;

				.callout-stat-container {
					display: table;
					padding: 0 6px;

					.callout-stat-item {
						display: table-cell;
						vertical-align: middle;
						height: 132px;

						span {
							display: block;
							color: $brandcolor3;
							font-size: 28px;
							font-weight: 800;
						}
					}
				}
			}
		}

		.col-md-4 {
			float: right;
			padding: 0 15px;

			#calendar {
				margin-top: -60px;

				&.collapse {
					display: block;
					visibility: visible;
					height: auto !important;
				}

				.calendar-title {
					box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
				}

				.calendar-list .calendar-item {
					padding: 15px 0;

					&:nth-child(5) {
						display: block;
					}
				}
			}
		}
	}
}

@media (min-width: 1200px) {
	#features .item {
		height: 500px;
	}

	.home-content {
		.callout-main-stat .col-sm-7 .callout-main-container .callout-main-item {
			height: 133px;
		}

		.stats {
			padding: 22px 0 52px;

			.col-md-3 + .col-md-3 {
				display: block;
			}

			.callout-stat {
				height: 120px;
				border-radius: 120px;
				margin: 7px -10px;

				.callout-stat-container {
					padding: 0 6px;

					.callout-stat-item {
						height: 120px;

						span {
							font-size: 28px;
						}
					}
				}
			}
		}

		.col-md-4 #calendar .calendar-list .calendar-item {
			padding: 11px 0;
		}
	}
}

.page-title {
	position: relative;
	background: $brandcolor2-dark;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	background-image: url(webart/header-bkg/trees.jpg);
	background-repeat: no-repeat;
	background-size: 200%;
	font-weight: 500;
	font-family: "proxima-nova", sans-serif;
	box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.25);

	h1 {
		margin: 0;
		padding: 20px 0 20px 0;
		color: $white;
	}

	.breadcrumb {
		animation-delay: 0.4s;
		-webkit-animation-delay: 0.4s;
		background: none;
		color: $white;
		padding: 0 0 10px;
		margin: 0;
		border-radius: 0;

		a {
			color: $white;
			text-decoration: none;
		}
	}
}

.page-content {
	background: $white;
	padding: 1px 0 20px;
	min-height: 200px;
	transition: height 0.4s ease;

	.side-quote blockquote {
		padding-top: 40px;
		text-align: center;
		border: none;

		p {
			position: relative;
			background: $brandcolor2;
			color: $white;
			padding: 15px 15px 30px;
			font-weight: 500;
			font-family: "proxima-nova";

			&:after {
				content: "";
				position: absolute;
				right: 0;
				bottom: -30px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 30px 30px 0;
				border-color: transparent $brandcolor2 transparent transparent;
			}
		}

		footer {
			position: relative;
			background: none;
			padding: 10px 0;
			border: none;
			z-index: 1;
			text-align: left;
			padding: 10px 20px 0 0;
			color: $brandcolor1;

			&:before {
				content: "";
			}

			small {
				color: $gray-mid3;

				&:before {
					content: "";
				}
			}

			img {
				height: 100px;
				width: 100px;
				border-radius: 100px;
				background: $gray-dark3;
				float: left;
				border: 3px solid $white;
				margin: -40px 15px 0 0;
			}
		}
	}

	.search-results .results {
		padding: 10px 0;
	}

	.panel-group {
		&.faq .panel .panel-heading {
			background: none;

			.panel-title {
				color: $brandcolor2;
			}
		}

		.panel .panel-heading {
			background: $brandcolor1;

			.panel-title {
				color: $white;

				small {
					color: rgba(255, 255, 255, 0.75);
				}
			}
		}
	}

	.panel {
		margin-bottom: 0;
		margin-top: 5px;
	}

	.list-group {
		color: $gray-dark1;

		:focus {
			background: none;
		}

		a.list-group-item {
			text-decoration: none;
			color: $brandcolor3;

			&:hover {
				background: $brandcolor3;
				color: $white;
				text-decoration: none;
			}

			&:active, &:focus {
				color: $white;
				background: $brandcolor3-dark;
				text-decoration: none;
			}
		}
	}

	.pager li a {
		text-decoration: none;
		color: $white;
		background: $brandcolor2;

		&:hover, &:active {
			text-decoration: none;
			background: $brandcolor1;
			color: $white;
		}
	}

	#career-listings li a.btn, #volunteer-listings li a.btn {
		margin: 2px 0;
		color: $white;
	}

	.career-details li p, .volunteer-details li p {
		margin: 0;
		padding: 0;
	}

	.career-details li a.btn, .volunteer-details li a.btn {
		margin-bottom: 10px;
		color: $white;
	}

	.list-group {
		&.leadership li {
			color: $gray-mid3;

			&:nth-child(even) {
				background: $gray-white1;
			}

			&.leadership-exec small {
				color: $gray-dark1;
				font-size: 100%;
			}

			strong {
				color: $brandcolor1-light;
				font-size: 16px;
				font-weight: 500;
			}

			a {
				color: $brandcolor3;
			}

			i {
				color: $gray-dark1;
			}
		}

		&.donor-family li, &.funeral-directors li {
			padding: 15px;
		}
	}

	.list-group-partners li {
		padding: 15px;
	}

	.list-group {
		&.funeral-directory li, &.support-resources li {
			padding: 15px;
		}

		&.donor-family li:nth-child(even), &.funeral-directors li:nth-child(even) {
			background: $gray-white1;
		}
	}

	.list-group-partners li:nth-child(even) {
		background: $gray-white1;
	}

	.list-group {
		&.funeral-directory li:nth-child(even), &.support-resources li:nth-child(even) {
			background: $gray-white1;
		}

		&.donor-family li h3, &.funeral-directors li h3 {
			color: $brandcolor1-light;
			font-weight: 500;
			font-family: "proxima-nova", sans-serif;
		}
	}

	.list-group-partners li h3 {
		color: $brandcolor1-light;
		font-weight: 500;
		font-family: "proxima-nova", sans-serif;
	}

	.list-group {
		&.funeral-directory li h3, &.support-resources li h3 {
			color: $brandcolor1-light;
			font-weight: 500;
			font-family: "proxima-nova", sans-serif;
		}

		&.donor-family li h3 small, &.funeral-directors li h3 small {
			color: $gray-dark1;
		}
	}

	.list-group-partners li h3 small {
		color: $gray-dark1;
	}

	.list-group {
		&.funeral-directory li h3 small, &.support-resources li h3 small {
			color: $gray-dark1;
		}

		&.donor-family li h3 small span, &.funeral-directors li h3 small span {
			color: $gray-dark1;
			font-size: 90%;
		}
	}

	.list-group-partners li h3 small span {
		color: $gray-dark1;
		font-size: 90%;
	}

	.list-group {
		&.funeral-directory li h3 small span, &.support-resources li h3 small span {
			color: $gray-dark1;
			font-size: 90%;
		}

		&.donor-family li img, &.funeral-directors li img {
			width: 100%;
			height: auto;
			border-radius: 5px;
			margin-bottom: 20px;
		}
	}

	.list-group-partners li img {
		width: 100%;
		height: auto;
		border-radius: 5px;
		margin-bottom: 20px;
	}

	.list-group {
		&.funeral-directory li img, &.support-resources li img {
			width: 100%;
			height: auto;
			border-radius: 5px;
			margin-bottom: 20px;
		}

		&.donor-family li strong, &.funeral-directors li strong {
			color: $brandcolor1-light;
			font-size: 16px;
			font-weight: 500;
			font-family: "proxima-nova", sans-serif;
		}
	}

	.list-group-partners li strong {
		color: $brandcolor1-light;
		font-size: 16px;
		font-weight: 500;
		font-family: "proxima-nova", sans-serif;
	}

	.list-group {
		&.funeral-directory li strong, &.support-resources li strong {
			color: $brandcolor1-light;
			font-size: 16px;
			font-weight: 500;
			font-family: "proxima-nova", sans-serif;
		}

		&.donor-family li a, &.funeral-directors li a {
			color: $brandcolor3;
		}
	}

	.list-group-partners li a {
		color: $brandcolor3;
	}

	.list-group {
		&.funeral-directory li a, &.support-resources li a {
			color: $brandcolor3;
		}

		&.donor-family li a {
			&:hover, &:active, &:focus {
				color: $brandcolor3-dark;
				text-decoration: underline;
			}
		}

		&.funeral-directors li a {
			&:hover, &:active, &:focus {
				color: $brandcolor3-dark;
				text-decoration: underline;
			}
		}
	}

	.list-group-partners li a {
		&:hover, &:active, &:focus {
			color: $brandcolor3-dark;
			text-decoration: underline;
		}
	}

	.list-group {
		&.funeral-directory li a {
			&:hover, &:active, &:focus {
				color: $brandcolor3-dark;
				text-decoration: underline;
			}
		}

		&.support-resources li a {
			&:hover, &:active, &:focus {
				color: $brandcolor3-dark;
				text-decoration: underline;
			}
		}

		&.donor-family li i, &.funeral-directors li i {
			color: $brandcolor1;
		}
	}

	.list-group-partners li i {
		color: $brandcolor1;
	}

	.list-group {
		&.funeral-directory li i, &.support-resources li i {
			color: $brandcolor1;
		}

		&.donor-family li .media + .media, &.funeral-directors li .media + .media {
			margin-top: 5px;
		}
	}

	.list-group-partners li .media + .media {
		margin-top: 5px;
	}

	.list-group {
		&.funeral-directory li .media + .media, &.support-resources li .media + .media {
			margin-top: 5px;
		}
	}

	.faq .panel-heading {
		background: none;
		border-color: $gray-light1;
		padding: 0;

		a {
			text-decoration: none;
			display: block;
			background: $brandcolor3-dark;
			color: $white;
			padding: 10px 15px;

			&.collapsed {
				color: $brandcolor3;
				background: none;

				&:hover, &:active, &:focus {
					background: $brandcolor3;
					color: $white;
				}

				&:hover i, &:active i, &:focus i {
					color: $white;
				}

				i {
					display: block;
					color: $gray-dark1;

					+ i {
						display: none;
					}
				}
			}

			i {
				display: none;
				color: $white;

				+ i {
					display: block;
				}
			}
		}
	}
}

#funeral-director-map {
	#area, #area2, #area3, #area4, #area5, #area6 {
		opacity: 0.65;
		transition: all 0.1s ease;
	}

	#area:hover, #area2:hover, #area3:hover, #area4:hover, #area5:hover, #area6:hover {
		opacity: 1;
		cursor: pointer;
	}
}

#scroll-top {
	display: none;
	position: fixed !important;
}

#mobile-scroll-top {
	border: none;
	border-radius: 0px;
	background: $brandcolor2;
	color: $white;
	padding: 15px 0;
	font-weight: 300;
	font-family: "proxima-nova", sans-serif;
	font-size: 20px;
	text-align: center;
}

@media (min-width: 768px) {
	.page-title {
		background-size: contain;
		background-attachment: fixed;

		h1 {
			padding: 20px 0 5px 0;
		}
	}

	.page-content {
		padding: 20px 0;
		min-height: 600px;

		> .container {
			> .row > {
				.side-nav {
					border-right: 1px solid $gray-light1;
				}

				.main {
					border-left: 1px solid $gray-light1;
					margin-left: -1px;
					min-height: 400px;
				}
			}

			.list-group.donor-family li img {
				margin: 0;
			}
		}
	}

	#scroll-top {
		bottom: 0px;
		right: 0px;
		width: 40px;
		height: 40px;
		border-left: 1px solid $gray-light1;
		border-top: 1px solid $gray-light1;
		text-align: center;
		line-height: 40px;
		font-size: 20px;
		cursor: pointer;
		color: $gray-dark1;
		transition: background 0.4s ease;

		&:hover, &:active {
			background-color: $brandcolor3;
			color: $white;
		}
	}
}

@media (min-width: 992px) {
	.page-content .side-quote blockquote {
		padding-top: 0;
		border-left: 5px solid $gray-light1;
	}
}

.calendar-date {
	width: 80px;
	border: 1px solid $gray-light1;
	font-family: "proxima-nova", sans-serif;
	font-weight: 500;
	border-radius: 5px;
	overflow: hidden;
	margin-right: 10px;

	span {
		display: block;
	}

	.calendar-date-month {
		background: $brandcolor1;
		text-align: center;
		color: $white;
		padding: 5px 0;
		font-size: 16px;
		text-transform: uppercase;
	}

	.calendar-date-day {
		text-align: center;
		color: $brandcolor1-light;
		padding: 15px 0;
		font-size: 32px;
		font-weight: 700;
	}
}

.int-calendar {
	.calendar-grid {
		margin-top: 5px;
	}

	.calendar-list .events-a .data {
		display: none;
	}
}

p.sfcalendar-widget-nav {
	position: relative;
	background: $brandcolor1;
	font-family: "proxima-nova", sans-serif;
	font-weight: 500;
	color: $white;
	height: 50px;
	margin: 0;
	text-align: center;
	border: 1px solid $brandcolor1-dark;
	border-bottom: none;
	font-size: 24px;
	line-height: 50px;

	a {
		color: $white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		&.prev, &.next {
			position: absolute;
			width: 40px;
			height: 49px;
			text-indent: -999em;
			overflow: hidden;
			background-position: center center;
			background-repeat: no-repeat;
		}

		&.prev {
			left: 0px;
			background-image: url("../stylesheets/webart/icons/arrow-left-c.png");
		}

		&.next {
			right: 0px;
			background-image: url("../stylesheets/webart/icons/arrow-right-c.png");
		}
	}
}

div.sfcalendar-widget-cal {
	margin: 0 0 30px;
	border: 1px solid $gray-light3;
	border-top: none;

	table {
		width: 100%;
		margin: 0;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

		td, th {
			padding: 0;
			border: none;
			text-align: center;
		}

		thead th {
			font-size: 12px;
			line-height: 2;
			text-transform: uppercase;
			color: $gray-dark1;
			font-weight: normal;
			background: $gray-white1;
			border-bottom: 1px solid $gray-light2;
		}

		tbody {
			td {
				width: 49px;
				height: 49px;
				line-height: 49px;
				font-size: 12px;
				font-weight: bold;
				border-right: 1px solid $gray-light3;
				border-bottom: 1px solid $gray-light3;
				background: $gray-white2;
				text-shadow: $white 0 1px 0;

				&.inactive {
					background: $gray-white1;
					color: $gray-light3;
				}

				&.col7 {
					border-right: none;
				}

				a {
					display: block;
					width: 100%;
					height: 100%;
					line-height: 49px;
					text-align: center;
					color: $gray-dark1;
					text-decoration: none;

					&.selected {
						background: $gray-light1;
						color: $brandcolor1;
						text-decoration: none;
					}

					&.event {
						background: $brandcolor1-dark !important;
						text-shadow: $gray-dark3 0 1px 0;
						color: $white;
					}

					&:hover, &:focus, &:active {
						background: $brandcolor1-light;
						color: $white;
						text-shadow: $gray-dark3 0 1px 0;
						text-decoration: none;
					}
				}
			}

			tr {
				&:last-child td, &.last-child td {
					border-bottom: none;
				}
			}
		}
	}
}

.sfcalendar-widget-pagination, .sfcalendar-widget-period {
	display: none;
}

.callout {
	padding: 30px 15px;
	background: $brandcolor1;
	text-align: center;
	color: $white;
	box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid $white;
	border-top: 1px solid $white;
	background-image: url(webart/bkg/butterflies.svg);
	background-repeat: no-repeat;
	background-position: bottom right;
	background-size: 400%;

	h4 {
		text-transform: uppercase;
		font-weight: 600;
		font-family: "proxima-nova", sans-serif;
		font-size: 26px;
		margin: 0;
		padding: 0;
		color: $white;
	}

	p {
		font-weight: 300;
		font-family: "proxima-nova", sans-serif;
		font-size: 20px;
		color: $white;
	}

	.btn-primary {
		background: none;
		border-radius: 0;
		border-color: $brandcolor1-light;
		text-transform: uppercase;
		font-weight: 500;
		font-family: "proxima-nova", sans-serif;
		text-decoration: none;
		font-size: 16px;

		&:hover, &:active, &:focus {
			background: $brandcolor1-light;
		}

		i {
			margin-left: 10px;
		}
	}

	.col-sm-4 + .col-sm-4 a.btn {
		margin-top: -1px;
	}
}

footer {
	padding: 15px 0;
	background: $gray-white2;
	border-bottom: 1px solid $gray-light1;

	.col-sm-8 {
		border-bottom: 1px solid $gray-light1;
		margin-bottom: 20px;
	}

	.location-title {
		border-bottom: 1px solid $gray-light1;
		color: $gray-dark1;
		font-weight: 300;
		font-family: "proxima-nova", sans-serif;
		font-size: 24px;
		text-align: center;

		i {
			color: $brandcolor1;
		}

		a {
			position: relative;
			display: inline-block;
			font-size: 10px;
			border: 1px solid $brandcolor2;
			padding: 2px 4px;
			color: $white;
			background: $brandcolor2;
			transition: background 0.4s ease;

			&:hover, &:active, &:focus {
				text-decoration: none;
				border-color: $brandcolor1;
				background: $brandcolor1;
			}
		}
	}

	#locations-toggle {
		margin: -15px 0 0px;
		border: none;
		border-radius: 0px;
		background: $brandcolor2;
		color: $white;
		padding: 15px 0;
		font-weight: 300;
		font-family: "proxima-nova", sans-serif;
		font-size: 20px;
		text-align: center;

		&.collapsed span {
			display: none;

			+ span {
				display: inline;
			}
		}

		span {
			display: inline;
			text-transform: uppercase;

			+ span {
				display: none;
			}
		}
	}

	.donate-life {
		display: block;
		text-indent: -9999em;
		margin: 20px;
		background-image: url(/stylesheets/webart/logos/donate-life.svg);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		height: 100px;
	}

	.social-media {
		list-style: none;
		padding: 0;
		background: $brandcolor1;
		margin: 0 -15px -15px;

		li {
			margin: 0;
			padding: 0;
			float: left;
			width: 20%;
			text-align: center;
			border-right: 1px solid $brandcolor1-light;

			&:nth-child(5) {
				border-right: none;
			}

			&:hover, &:active, &:focus {
				background: $brandcolor1-light;
			}

			a {
				display: block;
				font-size: 20px;
				color: $white;
				transition: color 0.4s ease;

				&:hover {
					color: $white;
				}
			}
		}
	}

	.address {
		margin: 10px 0;
		border-bottom: 1px solid $gray-light1;
		padding-bottom: 10px;
		text-align: center;

		+ .address {
			border: none;
		}

		.address-callout {
			background: $brandcolor1;
			color: $white;
			text-align: center;
			font-size: 20px;
			margin-top: 20px;
			padding: 5px 0;

			+ .address-callout {
				margin-top: 5px;
				margin-bottom: 20px;
			}

			a {
				color: $white;
				text-decoration: none;

				&:hover, &:active, &:focus {
					text-decoration: none;
				}
			}
		}

		p {
			margin: 0;
			padding: 0;
			font-size: 18px;
			font-weight: 300;
			font-family: "proxima-nova", sans-serif;
			color: $gray-dark1;

			strong {
				font-weight: 600;
			}

			a {
				text-decoration: none;

				&:hover, &:active, &:focus {
					text-decoration: none;
				}
			}
		}
	}
}

.copyright {
	padding: 10px;
	font-size: 12px;
	color: $gray-dark1;
	text-align: center;

	.copyright-links {
		display: block;
		margin-top: 5px;

		a {
			color: $brandcolor1;

			&:first-child::before {
				content: "";
			}

			&::before {
				content: " | ";
				color: $brandcolor1;
			}
		}
	}
}

@media (min-width: 768px) {
	.callout {
		background-attachment: fixed;
		background-size: 200%;
		border-top: none;

		.col-sm-4 + .col-sm-4 a.btn {
			margin-top: 0;
		}

		.btn-primary {
			transition: background 0.4s ease;
		}
	}

	footer {
		.col-sm-8 {
			border: none;
			margin-bottom: 0;
			margin-top: 20px;
			padding: 0 15px;

			+ .col-sm-4 {
				margin-top: 40px;
			}
		}

		.col-md-2 {
			border-right: 1px solid $gray-light1;
			min-height: 200px;

			&:last-child {
				border-right: none;
				border-left: 1px solid $gray-light1;
			}

			&:nth-child(4) {
				display: none;
			}
		}

		.col-md-4 .col-md-6:last-child {
			border-left: 1px solid $gray-light1;
		}

		#locations.collapse {
			display: flex;
			visibility: visible;
			height: auto !important;
		}

		.location-title {
			text-align: left;
			font-size: 24px;
			margin-bottom: 20px;

			a {
				float: right;
				text-decoration: none;
			}
		}

		ul.quicklinks {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin: 0;
				padding: 0;

				&:first-child {
					border-bottom: 1px solid $gray-light1;
					color: $gray-dark1;
					font-weight: 500;
					font-family: "proxima-nova", sans-serif;
					font-size: 16px;
					margin: 12px 0 10px;
				}

				a {
					color: $brandcolor1;
					font-weight: 500;
					font-family: "proxima-nova", sans-serif;
					text-decoration: none;

					&:hover, &:active, &:focus {
						color: $brandcolor3;
						text-decoration: underline;
					}
				}
			}
		}

		.donate-life {
			height: 150px;
		}

		.social-media {
			background: none;
			margin: 0;

			li {
				border: none;
				margin: 0;

				&:hover, &:active, &:focus {
					background: none;
				}

				a {
					color: $gray-dark1;
					font-size: 24px;

					&:hover {
						color: $brandcolor3;
					}
				}
			}
		}

		.address {
			border: none;
			margin: 0 0 15px;
			text-align: left;

			.address-callout {
				background: $white;
				border: 1px solid $gray-light1;
				color: $brandcolor1;
				text-align: center;
				font-size: 16px;
				margin-top: 0px;
				padding: 5px 0;

				+ {
					.address-callout a {
						pointer-events: auto;
					}

					.address {
						margin: 0;
					}
				}

				a {
					color: $brandcolor1;
				}
			}

			p {
				font-size: 14px;

				a {
					pointer-events: none;
					color: $gray-dark1;
				}
			}
		}
	}

	.copyright {
		text-align: left;
		padding: 20px 0;

		.dsm-register {
			margin-top: -43px;
			margin-bottom: -20px;
		}

		.copyright-links {
			display: inline;
			margin: 0;

			a {
				text-decoration: none;

				&:first-child::before {
					content: " | ";
				}

				&:hover, &:active, &:focus {
					color: $brandcolor3;
				}
			}
		}
	}
}

@media (min-width: 992px) {
	footer {
		padding: 30px 0;

		.location-title {
			font-size: 20px;
		}

		.col-sm-8 {
			margin-top: 0;

			+ .col-sm-4 {
				margin-top: 0;
			}
		}

		.social-media li a {
			font-size: 20px;
		}
	}
}

@media (min-width: 1200px) {
	footer {
		.location-title, .social-media li a {
			font-size: 20px;
		}
	}
}

@import "legacy/animate";
